@import './colors';


html, body {
    background-color: $appBackground;
    color: white;
    //font-family: Whitney, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-family: Open Sans, Sarala, sans-serif;
    font-style: normal;
    margin: 0;

    scrollbar-color: rgb(30, 33, 36) rgb(47, 49, 54);
    scrollbar-color: dark;

    -webkit-overflow-scrolling: touch;

    // Text Readability
    font-feature-settings: 'liga';
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: subpixel-antialiased;

    overflow-x: hidden;
}
