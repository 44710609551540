@import '../../colors';


.page {
    .modal {
        margin-left: auto;
        margin-top: 15vh;
        width: 400px;

        .modal-header {
            color: #DDDDDD;
            font-size: 36px;
            font-weight: 600;
            line-height: 65px;
            text-align: right;

            span.bold {
                color: white;
                font-weight: 800;
            }
        }
        .modal-body {
            color: #989AA0;
            font-size: 24px;
            font-weight: 400;
            line-height: 49px;
            text-align: right;
        }
        .modal-footer {
            display: flex;
            justify-content: center;

            a {
                align-items: center;
                display: flex;
                flex-flow: row nowrap;

                background-color: $NotSoGrayDark;
                border-radius: 10px;
                color: white;
                cursor: pointer;
                margin-top: 40px;
                opacity: 1;
                padding: 10px;
                text-decoration: none;

                transition: opacity .15s ease-in-out;

                span {
                    display: flex;
                    align-items: center;

                    font-size: 28px;
                    font-weight: bold;
                    line-height: 40px;
                    white-space: nowrap;
                }

                .discord-logo {
                    background-image: url('../../../assets/images/logos/Discord-Logo-White.svg');
                    background-position: center;
                    background-repeat: no-repeat;
    
                    display: block;
    
                    height: 42px;
                    margin-right: 15px;
                    width: 42px;
                }
            }
            a.active, a:hover, a:focus {
                opacity: 0.7;
            }
        }
    }
}


:global(.app-desktop) {
    .page {
        align-self: initial;
    }
}

:global(.app-mobile) {
    .page {
        .modal {
            margin-top: 5vh;

            .modal-header {
                font-size: 28px;
                line-height: 50px;
                text-align: center;
            }
            .modal-body {
                font-size: 16px;
                line-height: 50px;
                text-align: center;
            }
        }
    }
}
