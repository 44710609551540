@import './colors';
@import './General.m';


.nav {
    @extend .noselect;

    display: flex;
    justify-content: center;

    height: auto;
    width: 100%;

    a {
        font-family: 'Montserrat';
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 29px;
        padding: 0 15px;
    }

    .brand {
        align-items: center;
        display: flex;
        flex-flow: row nowrap;
        opacity: 1;

        margin-right: auto;

        .brand-logo {
            background-image: url('../assets/images/b1.png');
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 50%;

            display: block;
            height: 48px;
            width: 48px;
        }

        .brand-text {
            font-size: 32px;
            margin-left: 5px;
        }
    }

    ul {
        display: flex;
        flex-flow: row nowrap;

        li {
            align-items: center;
            display: flex;
            flex-flow: row nowrap;
        }
    }

    .navbar {
        align-items: center;
        display: flex;
        flex-flow: row nowrap;

        max-width: var(--page-max-width);
        padding: 25px var(--padding-gutter-rule);
        width: 100%;
    }
}

:global(.app-desktop) {
    .nav {
        .navbar {
            justify-content: flex-end;
        }
    }
}

:global(.app-mobile) {
    .nav {
        .navbar {
            justify-content: center;

            .brand {
                position: absolute;

                margin-left: auto;
                margin-right: auto;
            }

            .trigger {
                display: flex;

                cursor: pointer;
                line-height: 64px;
                margin-right: auto;
            }
        }
    }
}


.sidenav-overlay {
    height: 100vh;
    width: 100vw;

    top: 0;
    left: 0;
    position: fixed;

    background-color: black;
    opacity: 0.3;
    z-index: 9998;
}

.sidenav {
    display: flex;
    flex-flow: column wrap;

    height: 100vh;
    width: 300px;

    top: 0;
    left: 0;
    position: fixed;

    background-color: $headerBackground;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    z-index: 9999;

    ul {
        display: flex;
        flex-flow: column nowrap;

        padding: 30px 0;
        width: 100%;

        li {
            width: 100%;
        }
    }

    a {
        padding: 15px;
        text-align: center;
        width: 100%;
    }
}
