@import '../colors';


.search {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;

    background-color: $NotSoGrayDark;
    border-radius: 10px;
    box-sizing: border-box;
    margin-bottom: 2.5px;
    padding: 10px;
    width: 100%;

    .search-input {
        align-items: center;
        display: flex;
        flex-flow: row nowrap;
        flex-grow: 1;

        background-color: $headerBackground;
        border-radius: 5px;
        padding: 5px;

        i.input-icon {
            height: 24px;
            width: 24px;
        }

        input {
            flex-grow: 1;

            font-size: 20px;
            text-overflow: ellipsis;
            width: 100%;
        }
    }
}
