::-webkit-scrollbar {
    height: 16px;
    width: 16px;
}
::-webkit-scrollbar-button, ::-webkit-scrollbar-corner {
    display: none;
}
::-webkit-scrollbar-track {
    margin: 2px;
}
::-webkit-scrollbar-track, ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: transparent;
    border: 3px solid transparent;
    border-radius: 8px;
}
::-webkit-scrollbar-track-piece {
    background-color: rgb(47, 49, 54);
    border: 3px solid rgb(47, 49, 54);
    border-radius: 8px;
}
::-webkit-scrollbar-thumb {
    background-color: rgb(30, 33, 36);
    border: 3px solid rgb(47, 49, 54);
}
::-webkit-scrollbar-thumb:hover {
    background-color: rgb(33, 36, 39);
}

// Disable scrollbar styling in safari, doesn't 100% reset the scrollbar tho
@media not all and (min-resolution:.001dpcm) { 
    @supports (-webkit-appearance: none) {
        ::-webkit-scrollbar {
            all: unset;
        }
        ::-webkit-scrollbar-button, ::-webkit-scrollbar-corner {
            all: unset;
        }
        ::-webkit-scrollbar-track {
            all: unset;
        }
        ::-webkit-scrollbar-track, ::-webkit-scrollbar-thumb {
            all: unset;
        }
        ::-webkit-scrollbar-track-piece {
            all: unset;
        }
        ::-webkit-scrollbar-thumb {
            all: unset;
        }
        ::-webkit-scrollbar-thumb:hover {
            all: unset;
        }
    }
}
