$appBackground: rgb(66, 68, 77);

$blurple: rgb(114, 137, 218);
$blurpleDark: rgb(103, 123, 196);

$headerBackground: rgb(54, 54, 57);

$NotSoBlue: rgb(40, 121, 255);
$NotSoBlueDark: rgb(20, 101, 235);

$NotSoGray: rgb(54, 54, 57);
$NotSoGrayLight: rgb(66, 68, 77);
$NotSoGrayDark: rgb(44, 44, 47);

$NotSoGreen: rgb(100, 225, 105);
$NotSoGreenDark: rgb(59, 165, 92);
$NotSoGreenDarker: rgb(39, 135, 62);

$NotSoRed: rgb(240, 71, 71);

$NotSoYellow: rgb(250, 166, 26);
$NotSoYellowDark: rgb(220, 136, 26);
$NotSoYellowDarker: rgb(190, 116, 26);
$NotSoYellowDarkest: rgb(170, 96, 26);
